<script>
export default {
  data: () => ({
    currentSlot: 'button'
  }),
  render: function (/*createElement*/) {
    const slot = this.$scopedSlots[this.currentSlot]
    const clickAction = this.currentSlot === 'button'
      ? () => (this.currentSlot = 'confirm')
      : () => (this.currentSlot = 'button')

    return slot(clickAction)
  }
}
</script>
