<template>
  <v-dialog
    v-model="dialog">
    <template v-slot:activator="{on}">
      <v-text-field
        v-model="value"
        :name="config.name"
        :label="config.label"
        :loading="loading"
        :rules="rules"
        clearable
        readonly
        v-on="on"
        />
    </template>
    <v-card>
      <v-card-title>
        {{config.label}} / {{collectionsConfig.getCollectionLabel(collectionId)}}
      </v-card-title>
      <v-card-text>
        <collection-table
          :loading="collection.loading"
          :items="collection.items"
          :schema="collection.schema"
          :channels="collection.channels"
          :relations="collection.relations"
          :reverse-relations="collection.reverseRelations"
          :suites="collection.suites"
          :sortable="true"
          :searchable="true"
          >
          <template v-slot:edit-item="{item, schema}">
            <v-btn text color="primary" @click="selectItem(item)">
              {{translate('Select')}}
            </v-btn>
          </template>
        </collection-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">{{translate('Cancel')}}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
  <!--<span>pick item {{config}}</span>-->
</template>
<script>
import {mapGetters} from 'vuex'
import CollectionTable from '@/components/collections/collection-table.vue'

export default {
  props: ['config', 'rules', 'loading'],
  data: () => ({
    dialog: false,
    value: ''
  }),
  components: {
    CollectionTable
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'translate']),
    collectionId () { return this.config.collection }
  },
  asyncComputed: {
    collection: {
      async get () {
        if (!this.dialog) {
          return {
            loading: true,
            items: [],
            schema: {},
            channels: {},
            relations: {},
            reverseRelations: {},
            suites: {}
          }
        }
        let loadAttributes = this.collectionsConfig.getTableAttributesForLoading(this.collectionId)
        let cd = await this.api.collections.getCollection({collectionId: this.collectionId, query: {
          attributes: loadAttributes && loadAttributes.join(',')
        }})
        return cd
      },
      default: {
        loading: true,
        items: [],
        schema: {},
        channels: {},
        relations: {},
        reverseRelations: {},
        suites: {}
      }
    }
  },
  methods: {
    selectItem (item) {
      this.value = item.key
      this.dialog = false
    }
  }
}
</script>
