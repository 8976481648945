<template>
  <v-expansion-panels v-if="inputElements.length">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{action.title}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          :key="formKey"
          v-model="formValid">
          <v-component
            :is="element"
            :config="config"
            :loading="loading"
            :rules="rules"
            :key="index"
            v-for="({element, config, rules}, index) in inputElements" />
        </v-form>

        <v-row>
          <v-spacer/>
          <v-btn
            small
            :disabled="!formValid"
            :loading="loading"
            @click="saveForm">
            {{submitLabel}}
          </v-btn>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import {mapGetters} from 'vuex'
import keyBy from 'lodash/keyBy'
import HtmlInput from './html-input.vue'
import CollectionItemPicker from './collection-item-picker.vue'
const htmlInputTypes = keyBy([
  'checkbox', 'color', 'date', 'datetime-local',
  'email', 'month', 'number', 'password', 'radio',
  'range', 'search', 'tel', 'text', 'time', 'url', 'week'
])

export default {
  props: ['action', 'actionParameters'],
  data: () => ({
    loading: false,
    formValid: false,
    mutations: 1
  }),
  computed: {
    ...mapGetters(['api', 'translate']),
    submitLabel () {
      let {form: {
        submit: {
          label = this.translate('Save')
        } = {}
      } = {}} = this.action
      return label
    },
    inputElements () {
      return this.mutations && Object.values(this.action.form.input || [])
        .map(config => ({
          config,
          rules: this.getInputRules(config),
          element: this.getInputElement(config.type)
        }))
        .filter(({element}) => element)
    },
    formKey () {
      return `${this.action.actionId}/${this.mutations}`
    }
  },
  methods: {
    getInputElement (type) {
      if (htmlInputTypes[type] === type) {
        return HtmlInput
      }
      if (type === 'collection-item') {
        return CollectionItemPicker
      }
    },
    getInputRules ({/*type,*/ rules = []}) {
      return rules.map(({pattern, message}) => {
        if (pattern && message) {
          let re = new RegExp(pattern)
          return v => re.test(v) || message
        }
      })
      .filter(v => v)
    },
    async saveForm () {
      const formData = new FormData(this.$refs.form.$el)
      this.loading = true
      try {
        await this.api.actions
          .postFormAction({
            action: this.action,
            actionParameters: this.actionParameters,
            formData
          })
        ++this.mutations
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>
