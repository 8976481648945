<template>
  <v-card tile style="width: 100%">
    <v-card-text>
      <vue-dropzone
        :id="dz.id"
        :key="dz.id"
        :options="dz.options"
        v-on:vdropzone-sending="dropzoneSending"
        v-on:vdropzone-complete="dropzoneComplete"
        v-on:vdropzone-error="dropzoneError">
      </vue-dropzone>
    </v-card-text>
  </v-card>
</template>
<script>

import {mapGetters} from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import parseUrl from 'url-parse'

const appendQuery = (url, query) => {
  let parsed = parseUrl(url, true)
  parsed.query = {
    ...query,
    ...parsed.query,
  }
  return parsed.toString()
}

export default {
  props: ['action', 'actionParameters'],
  data: () => ({
    dialog: false
  }),
  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapGetters(['notifications']),
    dz () {
      let {actionId, url, upload} = this.action
      let {accept} = upload || {}
      return {
        id: actionId,
        options: {
          url: appendQuery(url, this.actionParameters),
          thumbnailWidth: 128,
          acceptedFiles: accept || undefined,
          parallellUploads: 1,
          dictDefaultMessage: this.action.title || 'undefined'
        }
      }
    }
  },
  methods: {
    dropzoneComplete () {
      this.$emit('action-complete')
    },
    dropzoneSending (/*file, xhr, formData*/) {
    },
    dropzoneError (file) {
      this.notifications.emit('notification-message', {
        type: 'error',
        message: `Error uploading ${file.name}`
      })
    }
  }
}
</script>
