<template>
  <v-list>
    <v-list-item
      v-for="({actionId, action, component}) in actionComponents"
      :key="actionId">
      <!--{{action}}-->
      <component :is="component" :action="action" :actionParameters="actionParameters" />
    </v-list-item>
  </v-list>
</template>
<script>
import get from 'object-get'
import sortBy from 'lodash/sortBy'
import {mapGetters} from 'vuex'
import UploadActionCard from './upload/upload-action.vue'
import FormActionElement from './form/form-action.vue'
export default {
  props: ['actions', 'actionParameters'],
  computed: {
    ...mapGetters(['api', 'translate']),
    actionComponents () {
      const getActionComponent = (action) => {
        let {type, actionId, upload, form} = action
        if ((type === 'upload') && upload) {
          return {
            actionId,
            action,
            component: UploadActionCard
          }
        }
        if ((type === 'form') && form) {
          return {
            actionId,
            action,
            component: FormActionElement
          }
        }
      }
      return sortBy(this.actions, a => get(a, 'ui.order') || 0)
        .map(getActionComponent)
        .filter(v => v)
    }
  }
}
</script>
