<template>
  <v-text-field
    :name="config.name"
    :type="config.type"
    :label="config.label"
    :placeholder="config.placeholder"
    :loading="loading"
    :rules="rules"
    />
</template>
<script>
export default {
  props: ['config', 'rules', 'loading']
}
</script>
